import clsx from 'clsx';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/kenyaLogo.jpeg'
import { paths } from '../../../configs/constants';
import { SvgIcon } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

interface ChildComponentProps {
    icon: SvgIconComponent;
}

export default function ReinsuranceSideBar({ navMenuList, toggleSidebar }: any) {
    const navigate = useNavigate()
    return (
        <div className={clsx('bg-white sideMenuLayout', toggleSidebar && 'sideMenuCollapsed')}>
            <div className='pb-4 px-3 text-center sideMenuTitleSection' role="button" onClick={() => navigate(paths.reinsurance.Dashboard)}>
                <img draggable={false} src={logo} alt={"Bharat-RE"} style={{ height: '100px' }} />
            </div>
            <div className="bg-dark-blue sideMenuList h-100">
                <ul className="p-3 nav flex-column gap-1" id="" style={{ whiteSpace: "nowrap", marginBottom: "6rem" }}>
                    {navMenuList?.map((item: any, index: number) => <NavItem key={index} index={index} item={item} />)}
                </ul>
            </div>
        </div>
    )
}
const NavIcon: React.FC<ChildComponentProps> = ({ icon: Icon = '' }) => {
    if (Icon === '') return null;
    return typeof Icon === "string" ? <img src={Icon} width={24} /> : <Icon className='fw300 fs-2' />;
}

const NavItem = ({ index, item }: any) => {
    if (!item?.sub) {
        return (
            <li>
                <NavLink to={item?.to} className={clsx('nav-link d-flex align-items-center side-menu-item rounded--1 text-white gap-2')} style={{ padding: '1rem 1.25rem' }}>
                    <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                        <div className='imgActive' >
                            <NavIcon icon={item?.icon} />
                        </div>
                    </div>
                    <div className="flex-shrink-1">{item?.name}</div>
                </NavLink>
            </li>
        );
    }

    if (item?.sub) {
        return (
            <li>
                <NavLink to={item?.to} className="nav-link d-flex accordion-button collapsed bg-transparent border-0 side-menu-item rounded--1 text-white" data-bs-toggle="collapse" data-bs-target={'#ul-menu-' + index} aria-expanded="false">
                    <div className="flex-shrink-0" style={{ width: '2.4rem' }}>
                        <div className='imgActive' >
                            <NavIcon icon={item?.icon} />
                        </div>
                    </div>
                    <div className="flex-shrink-1">{item?.name}</div>
                </NavLink>
                <div className="accordion-collapse collapse" id={'ul-menu-' + index} data-bs-parent="#side-menu">
                    <ul className="nav  flex-column pb-3 mt-3" style={{ marginLeft: '2.6rem' }}>
                        {item?.sub?.map((subItem: any, subIndex: number) => (
                            <li key={subIndex}>
                                <NavLink to={subItem?.to} className="nav-link fw-normal side-menu-sub-item rounded-0  text-white">
                                    {subItem?.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
            </li>
        );
    }
    return null;
};
