import moment from 'moment';
import 'moment-timezone';
import logo from "../assets/images/kenyaLogo.jpeg";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from 'xlsx';
import { authService } from './authService';

export const base64 = {
    encode(text: string) {
        return btoa(text);
    },
    decode(text: string) {
        return atob(text);
    }
}

export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB (adjust as needed)

export const numberFormat = (val: number = 0) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatDateTime = (date: any) => {
    let dateReturn = '-';
    try {
        if (date !== '' && date !== null) {
            const _date = moment.tz(date, moment.tz.guess()).toDate();
            dateReturn = moment(_date).format('DD-MMM-YYYY hh:mm:ss A');
        }
    } catch (e) {
        dateReturn = '-';
    }

    return dateReturn;
}

export const formatDate = (date: any) => {
    let dateReturn = '-';
    try {
        if (date !== '' && date !== null) {
            const _date = moment.tz(date, moment.tz.guess()).toDate();
            dateReturn = moment(_date).format('DD-MMM-YYYY');
        }
    } catch (e) {
        dateReturn = '-';
    }

    return dateReturn;
}

export const isValidDate = (dateString: any) => {
    return moment(dateString, moment.ISO_8601, true).isValid();
}
export const currencyFormat = (value: number) =>
    new Intl.NumberFormat('en-Us', {
        style: 'currency',
        currency: 'USD'
    }).format(value || 0);

export const formatNumber = (inputNumber: any) => {
    let formetedNumber = (Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{7})+\.)/g, '$&,');
    let splitArray = formetedNumber.split('.');
    if (splitArray.length > 1) {
        formetedNumber = splitArray[0];
    }
    return (formetedNumber);
};
export const formatNumber1 = (inputNumber: any) => {
    let formetedNumber = (Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{7})+\.)/g, '$&,');
    let splitArray = formetedNumber.split('.');
    if (splitArray.length > 1) {
        formetedNumber = splitArray[0];
    }
    return (formetedNumber);
};
export const generateTablePdf = (items: any) => {
    const doc = new jsPDF("p", "mm", "a4");
    let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.addImage(logo, 'PNG', 15, 0, 25, 13);
    doc.setTextColor("#40403f");
    doc.setFontSize(14)
    doc.text(items?.title, pageWidth / 2, 10, { align: 'center' });
    doc.setTextColor("#40403f");
    doc.setFontSize(8)
    doc.text('Date of Export', pageWidth - 30, 8, { align: 'center' })
    doc.setTextColor("#40403f");
    doc.setFontSize(9)
    doc.text(formatDateTime(new Date()), pageWidth - 33, 12, { align: 'center' });
    autoTable(doc, {
        head: items?.head,
        body: items?.body,
        styles: { halign: 'center' },
        headStyles: { fillColor: "gray" },
    });
    doc.save(`${items?.fileName}.pdf`);
}

export const generateTableExcel = (items: any) => {
    let wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(items?.data);
    XLSX.utils.book_append_sheet(wb, ws, items.sheet)
    XLSX.writeFile(wb, `${items?.fileName}.xlsx`)
}

export const proposalDraftFields = (items: any) => {

    const user = authService?.user();

    const item = JSON.parse(localStorage.getItem('proposal1_info') || '{}');
    const item2 = JSON.parse(localStorage.getItem('proposal_info2') || '{}');
    const item2_5 = JSON.parse(localStorage.getItem('proposal_info2_5') || '{}');
    const item3 = JSON.parse(localStorage.getItem('proposal_info3') || '{}');
    const item3_0 = JSON.parse(localStorage.getItem('proposal_info3_0') || '{}');
    const item4 = JSON.parse(localStorage.getItem('proposal_info4') || '{}');
    items = { ...item, ...item2, ...item2_5, ...item3, ...item3_0, ...item4, ...items };

    console.log(items)
    let docList: any = []
    if (items?.DocumentId?.length) {
        docList = [...docList, ...items?.DocumentId]
    }
    if (items?.ProposalDocument?.length > 0) {
        docList = [...docList, ...items?.ProposalDocument]
    }

    const add = JSON.parse(localStorage.getItem('proposal_info4_0') || '{}');

    let proposalAddOnCover: any = [];
    add?.proposalAddOnCover?.forEach((item: any) => {
        proposalAddOnCover.push({
            Id: item?.id || 0,
            description: item?.description || '',
            ProposalListId: 0,
        })
    })

    return {
        ProposalTypeId: items?.ProposalTypeId,
        Proportionaltypeid: items?.ProposalSubType,
        BharatReRefNo: items?.BharatReRefNo,
        Insured: items?.Insured,
        branchName: user?.branchName || "",
        Userid: user?.id || 0,
        coInsurerName: items?.CoInsurerName || '',
        coInsurerCategoryID: items?.CoInsurerCategoryID || 0,
        coInsurerPercentage: items?.CoInsurerPercentage || 0,
        CategoryId: items?.CategoryId,

        leaderName: items?.LeaderName || '',
        fotenable: items?.FOTenable,
        reinsurertermsfileUrl: items?.reinsurertermsfileUrl || '',
        leadReinsurerName: items?.leadReinsurerName || '',
        OurShare: items?.Ourshare || [],
        RIrequirement: items?.RIrequirement || 0,

        CedentId: items?.CedentId,
        State: items?.State,
        City: items?.City,
        RiskLocation: items?.RiskLocation,
        OccupancyId: items?.OccupancyId,
        PolicyPeriod: null,
        PeriodFrom: items?.PeriodFrom ? moment(items?.PeriodFrom)?.format('DD-MM-YYYY') : null,
        PeriodTo: items?.PeriodTo ? moment(items?.PeriodTo)?.format('DD-MM-YYYY') : null,
        PolicyTypeId: typeof items?.PolicyTypeId === 'number' ? items?.PolicyTypeId : 0,
        RiskClassification: items?.RiskClassification,
        USDRate: parseFloat(items?.UsdRate) || 0.0,
        ProposalDocument: docList,
        TotalSumInsure: items?.TotalSumInsure?.length > 0 ? items?.TotalSumInsure?.map((tItem: any) => {
            delete tItem.iNr;
            delete tItem.uSdvalue;
            return { ...tItem, inr: Number(tItem?.inr) || 0, usdValue: Number(tItem?.usdValue) || 0 }
        }) : [{}],
        LimitOfIndemnity: items?.LimitOfIndemnity || "",
        Deductibles: items?.Deductibles || "0",
        Currency: items?.currency || "0",
        OursharePercentage: items?.OursharePercentage || "",
        LeaderOurShareValue: items?.LeaderOurShareValue || '',
        LeaderTsiValue: items?.LeaderTsiValue || '',
        LeaderPmlValue: items?.LeaderPmlValue || '',
        Leader100PercentTsiValue: items?.Leader100PercentTsiValue || '',
        Leader100PercentPmlValue: items?.Leader100PercentPmlValue || '',
        Follower100PercentageSumInsured: items?.Follower100PercentageSumInsured || '',
        Follower100PercentagePremium: items?.Follower100PercentagePremium || '',
        FollowerSumInsuredValue: items?.FollowerSumInsuredValue || '',
        FollowerPmlValue: items?.FollowerPmlValue || '',
        _100percentagepremium: items?._100percentagepremium || '',

        renewalDocURL: items?.renewalDocURL || '',

        specialconditions: items?.specialconditions,
        uwclasses: items?.uwclasses,

        UserDetailsId: items?.CedentId,
        TotalSumInsured: parseFloat(items?.TotalSumInsured) || 0.0,
        TotalPremium: parseFloat(items?.TotalPremium) || 0.0,
        UnderWritingDetails: items?.UnderWritingDetails,
        AcceptTotalSumInsured: items?.AcceptTotalSumInsured || '',
        division: items?.division,
        communicationAddress: items?.ComAddress,

        SubmissionDate: items?.SubmissionDate,
        Coverage: items?.Coverage,
        InsuredInterest: items?.InsuredInterest,
        insuredInterest: items?.InsuredInterest,
        TransitType: items?.TransitType,
        Transits: items?.Transits,
        TransitDescription: items?.TransitDescription,
        GeographiclLimit: items?.GeographiclLimit,//typeof items?.GeographiclLimit === 'string' ? items?.GeographiclLimit  : items?.GeographiclLimit?.join(','),
        PackingDetails: items?.PackingDetails,
        PolicySumInsured: items?.PolicySumInsured,
        EstimatedAnualTurnover: parseFloat(items?.EstimatedAnualTurnover) || 0.0,
        PerBottomLimit: parseFloat(items?.PerBottomLimit) || 0.0,
        IntermediaryStorageDetails: items?.IntermediaryStorageDetails,
        PerLocationLimit: parseFloat(items?.PerLocationLimit) || 0.0,
        DomesticSales: items?.DomesticSales,
        ExportSales: items?.ExportSales,

        ImportPurchase: items?.ImportPurchase,
        DomesticPurchase: items?.DomesticPurchase,
        InterDepotMovement: items?.InterDepotMovement,
        DomesticSalesReturn: parseFloat(items?.DomesticSalesReturn) || 0.0,
        ExportSalesReturn: parseFloat(items?.ExportSalesReturn) || 0.0,
        PurchaseReturn: parseFloat(items?.PurchaseReturn) || 0.0,
        BasisOfValuation: parseFloat(items?.BasisOfValuation) || 0,
        Excess: parseFloat(items?.Excess) || 0.0,
        SanctionesCountryName: items?.SanctionesCountryName,

        OccupancyLiability: items?.Occupancy,

        TransitStartDate: items?.TransitStartDate,
        TransitEndDate: items?.TransitEndDate,
        CommdityDetails: items?.CommdityDetails,
        TransitFromTo: items?.TransitFromTo,
        ValueOfConsignment: parseFloat(items?.ValueOfConsignment) || 0.0,
        VesselDetails: items?.VesselDetails,
        PerTransitLimit: parseFloat(items?.PerTransitLimit) || 0.0,

        ShipmentDetails: items?.ShipmentDetails,
        EstimatedTurnoverPeriod: items?.EstimatedTurnoverPeriod,
        Conveyance: items?.Conveyance,
        Deductible: items?.Deductible,

        ClassType: items?.ClassType,
        RetroactiveDate: items?.RetroactiveDate,
        ContinuityDate: items?.ContinuityDate,
        AggregateSi: parseFloat(items?.AggregateSi) || 0.0,
        ProductLiabilitySi: parseFloat(items?.ProductLiabilitySi) || 0.0,
        TurnoverCovered: items?.TurnoverCovered,
        TurnoverValue: parseFloat(items?.TurnoverValue) || 0.0,

        liability: items?.liabilityRiskType,
        EventCancellations: items?.limitIndemnityList,

        BusinessActivities: items?.BusinessActivities,
        ProductLiabilityLimit: items?.ProductLiabilityLimit,

        ScopeOfWork: items?.ScopeOfWork,
        ProjectValue: items?.ProjectValue,

        ProductsToBeCovered: items?.ProductsToBeCovered,
        EnclosedList: items?.EnclosedList,

        GeographicalLimitIndiaId: items?.geographicalLimitIndiaId || 3,
        GeographicalIncludeUsaCanadaId: items?.geographicalIncludeUsaCanadaId || 3,
        GeographicalExcludeUsaCanadaId: items?.geographicalExcludeUsaCanadaId || 3,

        ProductLiabilityClaimsEnclosedDocUrl: items?.ProductLiabilityClaimsEnclosedDocUrl,
        ProductLiabilityRecallLimit: items?.ProductLiabilityRecallLimit,
        ProductRecallClimDocUrl: items?.ProductRecallClimDocUrl,
        FinancialLossLimit: parseFloat(items?.FinancialLossLimit) || 0.0,
        FinancialLossPastClaimHistoryDocUrl: items?.FinancialLossPastClaimHistoryDocUrl,
        ProductGuaranteeLimit: parseFloat(items?.ProductGuaranteeLimit) || 0.0,
        ProductGuaranteeClaimHistoryDocUrl: items?.ProductGuaranteeClaimHistoryDocUrl,
        IndustryType: items?.IndustryType,

        PrincipleName: items?.PrincipleName,
        PeriodOfCover: items?.PeriodOfCover,

        ProjectType: items?.ProjectType,
        ContractorName: items?.ContractorName,
        ProjectPeriod: items?.ProjectPeriod,
        ProjectDetails: items?.ProjectDetails,
        ProjectStartDate: items?.ProjectStartDate,
        EqZone: items?.eqZone,
        RentationProposed: items?.RentationProposed,
        Pml: items?.Pml,

        topLocationTsi: items?.topLocationTsi,
        indemnityPeriod: items?.indemnityPeriod,
        premiumPercentage: parseFloat(items?.Premium) || 0.0,

        addOnCoverages: items?.addOnCoverage || [],
        turnover: items?.turnover?.map((tItem: any) => { return { ...tItem, projectedTurnover: tItem?.projectedTurnover || 0 } }) || [],

        UnderWritingClasses: items?.underwritingClause || [],
        proposalUnderwriting: items?.underwritingClause || [],

        coverLimit: parseFloat(items?.CoverLimit) || 0,
        XOLpremium: parseFloat(items?.XolPremium) || 0,
        ProposalLayerList: items?.LayerList?.length ? items?.LayerList : [],

        domesticTurnover: items?.domesticTurnoverValue || 0,
        oecdTurnover: items?.oecdTurnoverValue || 0,
        usa_canadaTurnover: items?.usaCanadaTurnoverValue || 0,
        ROWTurnover: items?.rowTurnoverValue || 0,

        ReinsuranceConditions: items?.ReinsuranceConditions,
        LawAndJuridication: items?.LawAndJuridication,
        Claims: items?.Claims,
        RiCommission: parseFloat(items?.RiCommission) || 1,
        BharatReBrokerage: items?.BharatReBrokerage,
        TotalDeduction: parseFloat(items?.TotalDeduction) || 0.0,
        Ppw: items?.Ppw?.toString(),
        FacSignedLineScf: items?.FacSignedLineScf,
        FacSignedLineSct: items?.FacSignedLineSct,
        IndicativePremimumForLeadLine: items?.IndicativePremimumForLeadLine,
        FacPremiumForFollowLine: items?.FacPremiumForFollowLine,
        IndicativePremimumForLeadLineDollar: items?.IndicativePremimumForLeadLineDollar,
        FacPremiumForFollowLineDollar: items?.FacPremiumForFollowLineDollar,
        RequirementPercentage: parseFloat(items?.RequirementPercentage) || 0.0,
        ExtendedDueDate: items?.ExtendedDueDate ? moment(items?.ExtendedDueDate)?.format('DD-MM-YYYY') : null,
        DueDate: items?.DueDate ? moment(items?.DueDate)?.format('DD-MM-YYYY') : null,

        // new fields for Terrorism / Property 

        ProposalAssetDetails: items?.ProposalAssetDetails || [],
        ProposalMBDDetails: items?.ProposalMBDDetails || [],
        ProposalMLOPDetails: items?.ProposalMLOPDetails || [],
        ProposalOtherDetails: items?.ProposalOtherDetails || [],
        ProposalBIInsured: items?.ProposalBIInsured || [],

        PropertyDamage: items?.PropertyDamage,
        AssetDescriptionUploadEnable: items?.AssetDescriptionUploadEnable,
        AssetDescriptionUploaded: items?.AssetDescriptionUploaded,
        TotalAssetValue: items?.TotalAssetValue,

        MBD: items?.MBD,
        MBDdocumentID: items?.MBDdocumentID,
        MBDvalue: items?.MBDvalue,
        MBDSumInsured: Number(items?.MBDSumInsured || '0') || 0,

        MLOP: items?.MLOP,
        MLOPdocumentID: items?.MLOPdocumentID,
        MLOPvalue: items?.MLOPvalue,
        MLOPSumInsured: Number(items?.MLOPSumInsured || '0') || 0,

        OtherValue: items?.OtherValue,
        OtherListUploaded: items?.OtherListUploaded,
        OtherListDocID: items?.OtherListDocID,
        OtherSumInsured: Number(items?.OtherSumInsured || '0') || 0,

        proportionalTableType: items?.proportionalTableType || '',
        BusinessInterruption: items?.BusinessInterruption,
        BusinessValue: items?.BusinessAssetDescription,
        BusinessListDocID: items?.BusinessListDocID,
        BusinessSumInsured: items?.BusinessSumInsured,
        // Id:add?.Id||0,
        // Descripton:add?.Descripton||0,
        // ProposalListId:add?.ProposalListId||0,
        proposalAddOnCover: proposalAddOnCover,

        TotalSumAssured: items?.TotalSumAssured || 0,
        TotalSumAssuredUsd: items?.TotalSumAssuredUsd || 0,
        // Pml: items?.Pml || 0,
        Pmlusd: items?.Pmlusd || 0,
        TlsumInsured: items?.TlsumInsured || 0,
        TlsumInsuredUsd: items?.TlsumInsuredUsd || 0,
        // ----------

        IsTender: items?.IsTender || 3,
        IsRequiredSanctionedCountries: items?.IsRequiredSanctionedCountries || 3,
        IsDeclaration: items?.IsDeclaration || 3,
        IsHavingTransit: items?.NoOfTransit || items?.IsHavingTransit || 3,
        ProposalGeographicalLimitId: items?.ProposalGeographicalLimitId || 3,
        IsProposalForm: items?.IsProposalForm || 3,
        IsRislip: items?.IsRislip || 3,
        IsHaveLastThreeYearsFinancialStatement: items?.IsHaveLastThreeYearsFinancialStatement || 3,
        IsCriticalComponentsList: items?.IsCriticalComponentsList || 3,
        IsClaimDocEnclosed: items?.IsClaimDocEnclosed || 3,
        IsExpiringPolicyEnclosed: items?.IsExpiringPolicyEnclosed || 3,
        IsHaveProductRecallProcedure: items?.IsHaveProductRecallProcedure || 3,
        IsProductLiability: items?.IsProductLiability || 3,
        IsProductLiabilityClaimsEnclosed: items?.IsProductLiabilityClaimsEnclosed || 3,
        IsHaveProductLiabilityRecall: items?.IsHaveProductLiabilityRecall || 3,
        IsProductRecallClaimsExist: items?.IsProductRecallClaimsExist || 3,
        IsFinancialLoss: items?.IsFinancialLoss || 3,
        HaveFinancialLossPastClaimHistory: items?.HaveFinancialLossPastClaimHistory || 3,
        HaveProductGuarantee: items?.HaveProductGuarantee || 3,
        HaveProductGuaranteeClaimHistory: items?.HaveProductGuaranteeClaimHistory || 3,
        IsCyberQuestionnaire: items?.IsCyberQuestionnaire || 3,
        HaveListOfEntities: items?.HaveListOfEntities || 3,



        // ProposalId:add?.PropsalAddOnCover?.ProposalId,

        HaveContractorAgreement: items?.HaveContractorAgreement,
        HaveBarChart: items?.HaveBarChart,
        HavePlansDrawing: items?.HavePlansDrawing,


        // Marine Cargo
        PolicyType: items?.PolicyType,
        WarSrcc: items?.WarSrcc,
        NatureOfBusiness: items?.NatureOfBusiness,
        YearsOfBusiness: Number(items?.YearsOfBusiness) || 0,
        ProposerinCargoOwner: items?.ProposerinCargoOwner,
        Elaborate: items?.Elaborate,
        AnyOneVessel: Number(items?.anyOneVessel) || 0,
        AnyOneAircraft: Number(items?.anyOneAircraft) || 0,
        AnyOneBarge: Number(items?.anyOneBarge) || 0,
        AnyOneLandCoveyance: Number(items?.anyOneLandCoveyance) || 0,
        EstimatedAnnualCarring: Number(items?.estimatedAnnualCarring) || 0,
        TurnoverYears: Number(items?.turnoverYears) || 0,
        TurnoverYearsamount: Number(items?.turnoverYearsamount) || 0,
        TurnoverYearsValue: Number(items?.turnoverYearsValue) || 0,
        TurnoverYearsValueAmount: Number(items?.turnoverYearsValueAmount) || 0,
        TurnoverYearsTwo: Number(items?.turnoverYearsTwo) || 0,
        TurnoverYearsTwoAmount: Number(items?.turnoverYearsTwoAmount) || 0,
        BasicValueofEdition: items?.basicValueofEdition || '',
        Incoterms: items?.incoterms || 0,
        ConditionofCargo: items?.conditionofCargo || '',
        SurveyReportsFile: items?.SurveyReportsFile || '',
        InstituteCargoClauses: items?.instituteCargoClauses || '',
        WarAndStrikes: items?.WarAndStrikes || '',
        ExportImport: items?.ExportImport || '',
        InlandTransit: typeof items?.inlandTransit === 'object' ? '' : items?.inlandTransit || '',
        ProposalMarineModeOfConveyance: typeof items?.modeOfConveyance === 'object' ? '' : items?.modeOfConveyance || '',
        Description: items?.description || '',


        Contractor: items?.contractor || '',
        SubContrator: items?.subContrator || '',
        Trustees: items?.trustees || '',
        AgreedByInsurersPriorToAttachment: items?.agreedByInsurersPriorToAttachment || '',
        NatureDescriptionOfplantProject: items?.natureDescriptionOfplantProject || '',
        LocationOfProject: items?.locationOfProject || '',
        PeriodOfProject: items?.periodOfProject || '',
        NearestRailwayStation: items?.nearestRailwayStation || '',
        NearestOceanPort: items?.nearestOceanPort || '',
        TotalValueOfEquipmentToBeShipped: items?.totalValueOfEquipmentToBeShipped || 0,
        CountryOfOrginForAllEquiqment: items?.countryOfOrginForAllEquiqment || '',
        MethodOfTransportation: items?.methodOfTransportation || '',
        PackingSpecificationForAllCargoes: items?.packingSpecificationForAllCargoes || '',
        OnDeckStowageFile: items?.onDeckStowageFile || '',
        RiskAttachmentForTransitInsurnace: items?.riskAttachmentForTransitInsurnace || '',
        EndOfTransitInsurance: items?.endOfTransitInsurance || '',
        DateOfTestingScheduleToBegin: items?.dateOfTestingScheduleToBegin || '',
        TestPeriod: items?.testPeriod || '',
        ScheduleStartUpOrCommissioningDate: items?.scheduleStartUpOrCommissioningDate || '',
        DamageAndRepairsBeDoneLocally: items?.damageAndRepairsBeDoneLocally || '',
        ItemsSubjectToImportLicenseRestrictions: items?.itemsSubjectToImportLicenseRestrictions || '',
        ProvideFullDetails: items?.provideFullDetails || '',
        MachineryOrPlantUserEquipment: items?.machineryOrPlantUserEquipment || '',
        UploadTheFile: items?.uploadTheFile || '',
        SpecialExposureAndHazardAtProjectSite: items?.specialExposureAndHazardAtProjectSite || '',
        ClauseAgreementWithCAR: items?.clauseAgreementWithCAR || '',
        ConsequentialLossLimitRequest: items?.consequentialLossLimitRequest || '',
        IndemnityPeriodRequested: items?.indemnityPeriodRequested || '',
        TimeDeductibleRequested: items?.timeDeductibleRequested || '',
        DetailsOfProjectCargoAndHandlersFile: items?.detailsOfProjectCargoAndHandlersFile || '',
        BasicOfIndemnityProposed: items?.basicOfIndemnityProposed || '',
        EffectOfPlantOrProjectConstructionFile: items?.effectOfPlantOrProjectConstructionFile || '',
        TextFeildFileupload: items?.textFeildFileupload || '',
        DescriptionOfCargo: items?.descriptionOfCargo,
        WarAndSrcc: items?.warAndSrcc,
        DetailsOfVesselsAndConveyence: items?.detailsOfVesselsAndConveyence,
        ClaimsExperience: items?.claimsExperience,
        BillOfLadingAirwayBillParcelNo: items?.BillOfLadingAirwayBillParcelNo,
        ClaimExperienceFileUpload: items?.ClaimExperienceFileUpload,

        MarineCargoRequireOnDeckStowage: items?.MarineCargoRequireOnDeckStowage,
        ConveyanceForNonCriticalItemsPercentageTerms: items?.ConveyanceForNonCriticalItemsPercentageTerms,
        TransitInsuranceAttach: items?.TransitInsuranceAttach,
        PointToCease: items?.PointToCease,
        DateTestingScheduled: items?.DateTestingScheduled,
        LongWillTestingLast: items?.LongWillTestingLast,
        StartUpOrCommissioningDatePlantProject: items?.StartUpOrCommissioningDatePlantProject,
        RepairsBeDoneLocally: items?.RepairsBeDoneLocally,
        DoneByAssuredsPersonnel: items?.DoneByAssuredsPersonnel,
        DeferredUnpackingClauseBeRequired: items?.DeferredUnpackingClauseBeRequired,
        ProvideNamesOfExpeditersEmployed: items?.ProvideNamesOfExpeditersEmployed,
        ProvideNamesAndFullDetailsOfSurveyorsWishAppoint: items?.ProvideNamesAndFullDetailsOfSurveyorsWishAppoint,
        ProvideTheNamesSpecificClaimAdjusters: items?.ProvideTheNamesSpecificClaimAdjusters,
        AnyRouteSurveyAvailable: items?.AnyRouteSurveyAvailable,
        ProvideTheBasisOfIndemnityProposed: items?.ProvideTheBasisOfIndemnityProposed,
        OperationOfPlantOrProjectConstruction: items?.OperationOfPlantOrProjectConstruction,
        CommercialOperationsOrStartUpDateOfConstructionProject: items?.CommercialOperationsOrStartUpDateOfConstructionProject,
        AnyEquipmentnotrequiredProvideCargoInsurance: items?.AnyEquipmentnotrequiredProvideCargoInsurance,
        LimitRequiredVessel: items?.LimitRequiredVessel,
        LimitRequiredAir: items?.LimitRequiredAir,
        LimitRequiredTruck: items?.LimitRequiredTruck,
        AdditionalInformationPertainingCargoInsured: items?.AdditionalInformationPertainingCargoInsured,



        CargoFullShippingSchedule: items?.cargoFullShippingSchedule || [],
        TemporyStorgeOrDestination: items?.temporyStorgeOrDestination || [],
        ConsequetialLossWorkSheet: items?.consequetialLossWorkSheet || [],
        CommercialOperationOrStartUp: items?.commercialOperationOrStartUp || [],
        proposalAttachments: items?.proposalAttachments || [],
        DetailsOfCriticalItems: items?.detailsOfCriticalItems || [],


        OwnPower: items?.OwnPower,
        VoyageRiskOnly: items?.VoyageRiskOnly,
        Voyage: items?.voyage,
        PolicyTypeHull: items?.PolicyTypeHull,
        NameOfVesse: items?.NameOfVesse,
        FormerName: items?.FormerName,
        MasterName: items?.MasterName,
        Nationality: items?.Nationality,
        yearBuild: items?.yearBuild,
        PortOfRegistryAndRegisteredNo: items?.PortOfRegistryAndRegisteredNo,
        YearModified: items?.YearModified,
        Qualification: items?.Qualification,
        MaritimeExperience: items?.MaritimeExperience,
        CertificateOfCompetency: items?.CertificateOfCompetency,
        TypeOfTheVesse: items?.TypeOfTheVesse,
        ConstructionMaterial: items?.ConstructionMaterial,
        Flag: items?.Flag,
        NameOfTheClassificationSociety: items?.NameOfTheClassificationSociety,
        Tonnage: items?.Tonnage,
        GRTNRTDWT: items?.GRTNRTDWT,
        Loadline: items?.Loadline,
        Dimension: items?.Dimension,
        NationalityOfCrew: items?.NationalityOfCrew,
        NumbersOfCrew: items?.NumbersOfCrew,
        NumberOfCruewYears: items?.NumberOfCruewYears,
        TypeOfEngine: items?.TypeOfEngine,
        HorsePower: items?.HorsePower || 0,
        MaximumDesignedSpeedWithFullLoad: items?.MaximumDesignedSpeedWithFullLoad,
        NameOfTheBuilderYardExperience: items?.NameOfTheBuilderYardExperience,
        TypeOfTradeIsTheVesselorCraft: items?.TypeOfTradeIsTheVesselorCraft,
        StateTypeOfCargoConveyed: items?.StateTypeOfCargoConveyed,
        MaximumPermittedPassengerCapacity: items?.MaximumPermittedPassengerCapacity,
        OtherEquipmentsAndInstallation: items?.OtherEquipmentsAndInstallation,
        Others: items?.Others,
        ManufacturerAndDateOfMake: items?.ManufacturerAndDateOfMake,
        SerialAndModelNumber: items?.SerialAndModelNumber,
        BoilersManufacturerAndDateOfMake: items?.BoilersManufacturerAndDateOfMake,
        TypeOfFuelUsed: items?.TypeOfFuelUsed,
        StorageAndQuantityOfFuelCarried: items?.StorageAndQuantityOfFuelCarried,
        StateNumberOfOfficersAndCrews: items?.StateNumberOfOfficersAndCrews,
        NumberAndTypeOfFireExtinguisherPump: items?.NumberAndTypeOfFireExtinguisherPump,
        NumberAndTypeOfSafetyequipment: items?.NumberAndTypeOfSafetyequipment,
        OthersBePermittedToSailOrNavigateVessel: items?.OthersBePermittedToSailOrNavigateVessel,
        VesselNormallyMoored: items?.VesselNormallyMoored,
        StateCruisingTradingConfinesOrLimit: items?.StateCruisingTradingConfinesOrLimit,
        StateVoyageOrPeriodOfInsuranceCoverRequired: items?.StateVoyageOrPeriodOfInsuranceCoverRequired,
        StateTheAmountOfDeductible: items?.StateTheAmountOfDeductible,
        VesselPermittedFullDetails: items?.VesselPermittedFullDetails,
        LastTwelveMonthsOverhaulRepaired: items?.LastTwelveMonthsOverhaulRepaired,
        DetailsOfTugsNormallyUsed: items?.DetailsOfTugsNormallyUsed,
        ExpAndNatOfOwnerAndCrewOfTugs: items?.ExpAndNatOfOwnerAndCrewOfTugs,
        AnyContractOrAgreement: items?.AnyContractOrAgreement,
        MaximumNumberOfVessel: items?.MaximumNumberOfVessel,
        AnylocalOrGovernmentalAuthority: items?.AnylocalOrGovernmentalAuthority,
        CargoIsLoadedOnBoardTheTowedVesse: items?.CargoIsLoadedOnBoardTheTowedVesse,
        DeckhandsOnBoardTheTowedVessel: items?.DeckhandsOnBoardTheTowedVessel,
        AmountInsures: items?.AmountInsures,
        MarineHullClaimsExperience: items?.MarineHullClaimsExperience || [],
        MarineHullOperationsNavigationSurvey: items?.MarineHullOperationsNavigationSurvey || [],
        MarineHullDocumentEnclosed: items?.MarineHullDocumentEnclosed || [],
        MarineHullCoverageSpecification: items?.MarineHullCoverageSpecification || [],
        MarineHullPermisesInformation: items?.MarineHullPermisesInformation || [],
        MarineHullVesselDetails: items?.MarineHullVesselDetails || [],
        MarineHullVesselDimensions: items?.MarineHullVesselDimensions || [],
        MarineHullMachineryDetails: items?.MarineHullMachineryDetails || [],

        // Marine Liability
        lastFiveYearHasPort: items?.lastFiveYearHasPort || '',
        // lastFiveYearHasPortTrue: items?.lastFiveYearHasPortTrue || '',
        MarineHullVesselLiabilityInformation: items?.MarineHullVesselLiabilityInformation || [],
        MarineLiability: items?.MarineLiability,
        LegislationGovernsOperationPort: items?.LegislationGovernsOperationPort,
        ISPSCodeComplianceAtPort: items?.ISPSCodeComplianceAtPort,
        MarineLiabalityAdditionalInformation: items?.MarineLiabalityAdditionalInformation || [],
        MarineLiabilityPortThroughputRevenue: items?.MarineLiabilityPortThroughputRevenue || [],
        MarineLiabilityVariousServiceFacilitiesForms: items?.MarineLiabilityVariousServiceFacilitiesForms || [],
        MarineLiabilityInsurancePolicy: items?.MarineLiabilityInsurancePolicy || [],
        DescriptionMarineFacilities: items?.DescriptionMarineFacilities || [],
        DescriptionLandBasedFacilities: items?.DescriptionLandBasedFacilities || [],
        DescriptionOtherService: items?.DescriptionOtherService || [],
        MarineLiabilityContractorsEngagedByClient: items?.MarineLiabilityContractorsEngagedByClient || [],
        MarineLiabilityAgreedWithAnyContractors: items?.MarineLiabilityAgreedWithAnyContractors || [],
        MarineLiabilityActivities: items?.MarineLiabilityActivities || [],
        MarineLiabilityEmployeeInformation: items?.MarineLiabilityEmployeeInformation || [],
        MarineLiabilityCargoHandlingWorkers: items?.MarineLiabilityCargoHandlingWorkers || [],
        MarineLiabilityCustomerContactInformation: items?.MarineLiabilityCustomerContactInformation || [],
        MarineLiabilityStandardOfLibility: items?.MarineLiabilityStandardOfLibility || [],
        MarineLiabilityCustomerContactInformationContinued: items?.MarineLiabilityCustomerContactInformationContinued || [],
        MarineLiabilityClaims: items?.MarineLiabilityClaims || [],
        MarineLiabilityInsurenceClaimsHistory: items?.MarineLiabilityInsurenceClaimsHistory || [],
        MarineLiabilityOptionalExtensions: items?.MarineLiabilityOptionalExtensions || [],
        voyage: items?.voyage,


        Prodectionindemnity: items?.Prodectionindemnity || "",
        YearsOfExperience: items?.YearsOfExperience || '',
        NumbersOfCruewYears: items?.NumbersOfCruewYears || '',
        MarineHullLossHistory: items?.MarineHullLossHistory || [],
        MarineHullGeneralInformation: items?.MarineHullGeneralInformation || [],
        MarineHullVolumesAndParticularsOfTrade: items?.MarineHullVolumesAndParticularsOfTrade || [],
        MarineHullPercentagesOfParticularsOfTrade: items?.MarineHullPercentagesOfParticularsOfTrade || [],
        MarineHullTradingArea: items?.MarineHullTradingArea || [],
        MarineHullPercentageOfTradeInTradeRespectOfCargo: items?.MarineHullPercentageOfTradeInTradeRespectOfCargo || [],
        MarineHullNumberOfConsignmentOfCargoPerAnnum: items?.MarineHullNumberOfConsignmentOfCargoPerAnnum || [],
        MarineHullClaimsHistory: items?.MarineHullClaimsHistory || [],
        MarineHullContracts: items?.MarineHullContracts || [],
        MarineHullWareHousing: items?.MarineHullWareHousing || [],
        MarineHullWareHousingMaxValueCargoStored: items?.MarineHullWareHousingMaxValueCargoStored || [],
        MarineHullWareHousingPurpose: items?.MarineHullWareHousingPurpose || [],
        MarineHullWareHousingSecurityMeasure: items?.MarineHullWareHousingSecurityMeasure || [],
        MarineHullWareHousingAntiFireMeasures: items?.MarineHullWareHousingAntiFireMeasures || [],
        MarineHullOtherInformation: items?.MarineHullOtherInformation || [],

        MarineHullCargoHandled: items?.MarineHullCargoHandled || [],
        MarineHullConditionType: items?.MarineHullConditionType || '',
        MarineHullConditionValue: items?.MarineHullConditionValue || '',
        MarineHullClaimsExperienceFileUpload: items?.MarineHullClaimsExperienceFileUpload || '',
        MarineHullAmountInsured: items?.MarineHullAmountInsured || [],

        //Marine-DelayinStartup

        MarineDelayLocationOfProject: items?.MarineDelayLocationOfProject,
        InstallationProgrammewithAnticipated: items?.InstallationProgrammewithAnticipated,
        InstallationTime: items?.InstallationTime,
        ProjectFinanced: items?.ProjectFinanced,
        SpecialNature: items?.SpecialNature,
        ProductionCommenced: items?.ProductionCommenced,
        RepairsGuarantee: items?.RepairsGuarantee,
        ContigencyPlans: items?.ContigencyPlans,
        IndemnityPeriodDelayinStartUp: items?.IndemnityPeriodDelayinStartUp,
        DelayinStartUp: items?.DelayinStartUp,
        TimeExcessOpted: items?.TimeExcessOpted,

        MarineDelayPartyDeatailsUpload: items?.MarineDelayPartyDeatailsUpload || '',
        MarineDelayMajorComponentsUpload: items?.MarineDelayMajorComponentsUpload || '',
        MarineDelayManufacturerDetailsUpload: items?.MarineDelayManufacturerDetailsUpload || '',
        InstallationProgrammewithAnticipatedUpload: items?.InstallationProgrammewithAnticipatedUpload || '',
        SpecialNatureUpload: items?.SpecialNatureUpload || '',
        MarineDelayRepairUpload: items?.MarineDelayRepairUpload || '',
        ProductionCommencedUpload: items?.ProductionCommencedUpload || '',
        RepairsGuaranteeUpload: items?.RepairsGuaranteeUpload || '',
        ContigencyPlansUpload: items?.ContigencyPlansUpload || '',
        MarineDelayDetailsOfLossUpload: items?.MarineDelayDetailsOfLossUpload || '',

        MarineDelayPartyDeatails: items?.MarineDelayPartyDeatails || [],
        MarineDelayMajorComponents: items?.MarineDelayMajorComponents || [],
        MarineDelayManufacturerDetails: items?.MarineDelayManufacturerDetails || [],
        MarineDelayRepair: items?.MarineDelayRepair || [],
        MarineDelaySumInsuredAndClaimDetails: items?.MarineDelaySumInsuredAndClaimDetails || [],
        MarineDelayInStartUpDSU: items?.MarineDelayInStartUpDSU || [],
        MarineDelayDetailsOfLoss: items?.MarineDelayDetailsOfLoss || []

    }
}

export const getCopyProposal = (items: any) => {
    const user = authService?.user();

    return {
        saveasdraft: '',
        ProposalId: Number(items?.proposalId) || 0,
        ProposalTypeId: items?.ProposalTypeId,
        Proportionaltypeid: items?.ProposalSubType,
        BharatReRefNo: items?.BharatReRefNo,
        Insured: items?.Insured,
        branchName: user?.branchName || "",
        coInsurerName: items?.CoInsurerName || '',
        coInsurerCategoryID: items?.CoInsurerCategoryID || 0,
        coInsurerPercentage: items?.CoInsurerPercentage || 0,
        CategoryId: items?.CategoryId,

        leaderName: items?.LeaderName || '',
        fotenable: items?.FOTenable,
        reinsurertermsfileUrl: items?.reinsurertermsfileUrl || '',
        leadReinsurerName: items?.leadReinsurerName || '',
        OurShare: items?.Ourshare || [],
        RIrequirement: items?.RIrequirement || 0,

        CedentId: items?.CedentId,
        State: items?.State,
        City: items?.City,
        RiskLocation: items?.RiskLocation,
        OccupancyId: items?.OccupancyId,
        PolicyPeriod: null,
        PeriodFrom: items?.PeriodFrom ? moment(items?.PeriodFrom)?.format('DD-MM-YYYY') : null,
        PeriodTo: items?.PeriodTo ? moment(items?.PeriodTo)?.format('DD-MM-YYYY') : null,
        PolicyTypeId: typeof items?.PolicyTypeId === 'number' ? items?.PolicyTypeId : 0,
        RiskClassification: items?.RiskClassification,
        USDRate: parseFloat(items?.UsdRate) || 0.0,
        TotalSumInsure: items?.TotalSumInsure?.length > 0 ? items?.TotalSumInsure?.map((tItem: any) => {
            delete tItem.iNr;
            delete tItem.uSdvalue;
            return { ...tItem, inr: Number(tItem?.inr) || 0, usdValue: Number(tItem?.usdValue) || 0 }
        }) : [{}],
        LimitOfIndemnity: items?.LimitOfIndemnity || "",
        Deductibles: items?.Deductibles || "0",
        Currency: items?.currency || "0",
        OursharePercentage: items?.OursharePercentage || "",
        LeaderOurShareValue: items?.LeaderOurShareValue || '',
        LeaderTsiValue: items?.LeaderTsiValue || '',
        LeaderPmlValue: items?.LeaderPmlValue || '',
        Leader100PercentTsiValue: items?.Leader100PercentTsiValue || '',
        Leader100PercentPmlValue: items?.Leader100PercentPmlValue || '',
        Follower100PercentageSumInsured: items?.Follower100PercentageSumInsured || '',
        Follower100PercentagePremium: items?.Follower100PercentagePremium || '',
        FollowerSumInsuredValue: items?.FollowerSumInsuredValue || '',
        FollowerPmlValue: items?.FollowerPmlValue || '',
        _100percentagepremium: items?._100percentagepremium || '',

        renewalDocURL: items?.renewalDocURL || '',

        specialconditions: items?.specialconditions,
        uwclasses: items?.uwclasses,

        UserDetailsId: items?.CedentId,
        TotalSumInsured: parseFloat(items?.TotalSumInsured) || 0.0,
        TotalPremium: parseFloat(items?.TotalPremium) || 0.0,
        UnderWritingDetails: items?.UnderWritingDetails,
        AcceptTotalSumInsured: items?.AcceptTotalSumInsured || '',
        division: items?.division,
        communicationAddress: items?.ComAddress,

        SubmissionDate: items?.SubmissionDate,
        Coverage: items?.Coverage,
        InsuredInterest: items?.InsuredInterest,
        insuredInterest: items?.InsuredInterest,
        TransitType: items?.TransitType,
        Transits: items?.Transits,
        TransitDescription: items?.TransitDescription,
        GeographiclLimit: items?.GeographiclLimit,//typeof items?.GeographiclLimit === 'string' ? items?.GeographiclLimit  : items?.GeographiclLimit?.join(','),
        PackingDetails: items?.PackingDetails,
        PolicySumInsured: items?.PolicySumInsured,
        EstimatedAnualTurnover: parseFloat(items?.EstimatedAnualTurnover) || 0.0,
        PerBottomLimit: parseFloat(items?.PerBottomLimit) || 0.0,
        IntermediaryStorageDetails: items?.IntermediaryStorageDetails,
        PerLocationLimit: parseFloat(items?.PerLocationLimit) || 0.0,
        DomesticSales: items?.DomesticSales,
        ExportSales: items?.ExportSales,

        ImportPurchase: items?.ImportPurchase,
        DomesticPurchase: items?.DomesticPurchase,
        InterDepotMovement: items?.InterDepotMovement,
        DomesticSalesReturn: parseFloat(items?.DomesticSalesReturn) || 0.0,
        ExportSalesReturn: parseFloat(items?.ExportSalesReturn) || 0.0,
        PurchaseReturn: parseFloat(items?.PurchaseReturn) || 0.0,
        BasisOfValuation: parseFloat(items?.BasisOfValuation) || 0,
        Excess: parseFloat(items?.Excess) || 0.0,
        SanctionesCountryName: items?.SanctionesCountryName,

        OccupancyLiability: items?.Occupancy,

        TransitStartDate: items?.TransitStartDate,
        TransitEndDate: items?.TransitEndDate,
        CommdityDetails: items?.CommdityDetails,
        TransitFromTo: items?.TransitFromTo,
        ValueOfConsignment: parseFloat(items?.ValueOfConsignment) || 0.0,
        VesselDetails: items?.VesselDetails,
        PerTransitLimit: parseFloat(items?.PerTransitLimit) || 0.0,

        ShipmentDetails: items?.ShipmentDetails,
        EstimatedTurnoverPeriod: items?.EstimatedTurnoverPeriod,
        Conveyance: items?.Conveyance,
        Deductible: items?.Deductible,

        ClassType: items?.ClassType,
        RetroactiveDate: items?.RetroactiveDate,
        ContinuityDate: items?.ContinuityDate,
        AggregateSi: parseFloat(items?.AggregateSi) || 0.0,
        ProductLiabilitySi: parseFloat(items?.ProductLiabilitySi) || 0.0,
        TurnoverCovered: items?.TurnoverCovered,
        TurnoverValue: parseFloat(items?.TurnoverValue) || 0.0,

        liability: items?.liabilityRiskType,
        EventCancellations: items?.limitIndemnityList,

        BusinessActivities: items?.BusinessActivities,
        ProductLiabilityLimit: items?.ProductLiabilityLimit,

        ScopeOfWork: items?.ScopeOfWork,
        ProjectValue: items?.ProjectValue,

        ProductsToBeCovered: items?.ProductsToBeCovered,
        EnclosedList: items?.EnclosedList,

        GeographicalLimitIndiaId: items?.geographicalLimitIndiaId || 3,
        GeographicalIncludeUsaCanadaId: items?.geographicalIncludeUsaCanadaId || 3,
        GeographicalExcludeUsaCanadaId: items?.geographicalExcludeUsaCanadaId || 3,

        ProductLiabilityClaimsEnclosedDocUrl: items?.ProductLiabilityClaimsEnclosedDocUrl,
        ProductLiabilityRecallLimit: items?.ProductLiabilityRecallLimit,
        ProductRecallClimDocUrl: items?.ProductRecallClimDocUrl,
        FinancialLossLimit: parseFloat(items?.FinancialLossLimit) || 0.0,
        FinancialLossPastClaimHistoryDocUrl: items?.FinancialLossPastClaimHistoryDocUrl,
        ProductGuaranteeLimit: parseFloat(items?.ProductGuaranteeLimit) || 0.0,
        ProductGuaranteeClaimHistoryDocUrl: items?.ProductGuaranteeClaimHistoryDocUrl,
        IndustryType: items?.IndustryType,

        PrincipleName: items?.PrincipleName,
        PeriodOfCover: items?.PeriodOfCover,

        ProjectType: items?.ProjectType,
        ContractorName: items?.ContractorName,
        ProjectPeriod: items?.ProjectPeriod,
        ProjectDetails: items?.ProjectDetails,
        ProjectStartDate: items?.ProjectStartDate,
        EqZone: items?.eqZone,
        RentationProposed: items?.RentationProposed,
        Pml: items?.Pml,

        topLocationTsi: items?.topLocationTsi,
        indemnityPeriod: items?.indemnityPeriod,
        premiumPercentage: parseFloat(items?.Premium) || 0.0,

        addOnCoverages: items?.addOnCoverage || [],
        turnover: items?.turnover?.map((tItem: any) => { return { ...tItem, projectedTurnover: tItem?.projectedTurnover || 0 } }) || [],

        UnderWritingClasses: items?.underwritingClause || [],
        proposalUnderwriting: items?.underwritingClause || [],

        coverLimit: parseFloat(items?.CoverLimit) || 0,
        XOLpremium: parseFloat(items?.XolPremium) || 0,

        ProposalLayerList: items?.LayerList?.length ? items?.LayerList : [],
        
        domesticTurnover: items?.domesticTurnoverValue || 0,
        oecdTurnover: items?.oecdTurnoverValue || 0,
        usa_canadaTurnover: items?.usaCanadaTurnoverValue || 0,
        ROWTurnover: items?.rowTurnoverValue || 0,

        ReinsuranceConditions: items?.ReinsuranceConditions,
        LawAndJuridication: items?.LawAndJuridication,
        Claims: items?.Claims,
        RiCommission: parseFloat(items?.RiCommission) || 1,
        BharatReBrokerage: items?.BharatReBrokerage,
        TotalDeduction: parseFloat(items?.TotalDeduction) || 0.0,
        Ppw: items?.Ppw?.toString(),
        FacSignedLineScf: items?.FacSignedLineScf,
        FacSignedLineSct: items?.FacSignedLineSct,
        IndicativePremimumForLeadLine: items?.IndicativePremimumForLeadLine,
        FacPremiumForFollowLine: items?.FacPremiumForFollowLine,
        IndicativePremimumForLeadLineDollar: items?.IndicativePremimumForLeadLineDollar,
        FacPremiumForFollowLineDollar: items?.FacPremiumForFollowLineDollar,
        RequirementPercentage: parseFloat(items?.RequirementPercentage) || 0.0,
        ExtendedDueDate: items?.ExtendedDueDate ? moment(items?.ExtendedDueDate)?.format('DD-MM-YYYY') : null,
        DueDate: items?.DueDate ? moment(items?.DueDate)?.format('DD-MM-YYYY') : null,

        // new fields for Terrorism / Property 

        ProposalAssetDetails: items?.ProposalAssetDetails || [],
        ProposalMBDDetails: items?.ProposalMBDDetails || [],
        ProposalMLOPDetails: items?.ProposalMLOPDetails || [],
        ProposalOtherDetails: items?.ProposalOtherDetails || [],
        ProposalBIInsured: items?.ProposalBIInsured || [],

        PropertyDamage: items?.PropertyDamage,
        AssetDescriptionUploadEnable: items?.AssetDescriptionUploadEnable,
        AssetDescriptionUploaded: items?.AssetDescriptionUploaded,
        TotalAssetValue: items?.TotalAssetValue,

        MBD: items?.MBD,
        MBDdocumentID: items?.MBDdocumentID,
        MBDvalue: items?.MBDvalue,
        MBDSumInsured: Number(items?.MBDSumInsured || '0') || 0,

        MLOP: items?.MLOP,
        MLOPdocumentID: items?.MLOPdocumentID,
        MLOPvalue: items?.MLOPvalue,
        MLOPSumInsured: Number(items?.MLOPSumInsured || '0') || 0,

        OtherValue: items?.OtherValue,
        OtherListUploaded: items?.OtherListUploaded,
        OtherListDocID: items?.OtherListDocID,
        OtherSumInsured: Number(items?.OtherSumInsured || '0') || 0,

        proportionalTableType: items?.proportionalTableType || '',
        BusinessInterruption: items?.BusinessInterruption,
        BusinessValue: items?.BusinessAssetDescription,
        BusinessListDocID: items?.BusinessListDocID,
        BusinessSumInsured: items?.BusinessSumInsured,
        // Id:add?.Id||0,
        // Descripton:add?.Descripton||0,
        // ProposalListId:add?.ProposalListId||0,

        TotalSumAssured: items?.TotalSumAssured || 0,
        TotalSumAssuredUsd: items?.TotalSumAssuredUsd || 0,
        // Pml: items?.Pml || 0,
        Pmlusd: items?.Pmlusd || 0,
        TlsumInsured: items?.TlsumInsured || 0,
        TlsumInsuredUsd: items?.TlsumInsuredUsd || 0,
        // ----------

        IsTender: items?.IsTender || 3,
        IsRequiredSanctionedCountries: items?.IsRequiredSanctionedCountries || 3,
        IsDeclaration: items?.IsDeclaration || 3,
        IsHavingTransit: items?.NoOfTransit || items?.IsHavingTransit || 3,
        ProposalGeographicalLimitId: items?.ProposalGeographicalLimitId || 3,
        IsProposalForm: items?.IsProposalForm || 3,
        IsRislip: items?.IsRislip || 3,
        IsHaveLastThreeYearsFinancialStatement: items?.IsHaveLastThreeYearsFinancialStatement || 3,
        IsCriticalComponentsList: items?.IsCriticalComponentsList || 3,
        IsClaimDocEnclosed: items?.IsClaimDocEnclosed || 3,
        IsExpiringPolicyEnclosed: items?.IsExpiringPolicyEnclosed || 3,
        IsHaveProductRecallProcedure: items?.IsHaveProductRecallProcedure || 3,
        IsProductLiability: items?.IsProductLiability || 3,
        IsProductLiabilityClaimsEnclosed: items?.IsProductLiabilityClaimsEnclosed || 3,
        IsHaveProductLiabilityRecall: items?.IsHaveProductLiabilityRecall || 3,
        IsProductRecallClaimsExist: items?.IsProductRecallClaimsExist || 3,
        IsFinancialLoss: items?.IsFinancialLoss || 3,
        HaveFinancialLossPastClaimHistory: items?.HaveFinancialLossPastClaimHistory || 3,
        HaveProductGuarantee: items?.HaveProductGuarantee || 3,
        HaveProductGuaranteeClaimHistory: items?.HaveProductGuaranteeClaimHistory || 3,
        IsCyberQuestionnaire: items?.IsCyberQuestionnaire || 3,
        HaveListOfEntities: items?.HaveListOfEntities || 3,



        // ProposalId:add?.PropsalAddOnCover?.ProposalId,

        HaveContractorAgreement: items?.HaveContractorAgreement,
        HaveBarChart: items?.HaveBarChart,
        HavePlansDrawing: items?.HavePlansDrawing,


        // Marine Cargo
        PolicyType: items?.PolicyType,
        WarSrcc: items?.WarSrcc,
        NatureOfBusiness: items?.NatureOfBusiness,
        YearsOfBusiness: Number(items?.YearsOfBusiness) || 0,
        ProposerinCargoOwner: items?.ProposerinCargoOwner,
        Elaborate: items?.Elaborate,
        AnyOneVessel: Number(items?.anyOneVessel) || 0,
        AnyOneAircraft: Number(items?.anyOneAircraft) || 0,
        AnyOneBarge: Number(items?.anyOneBarge) || 0,
        AnyOneLandCoveyance: Number(items?.anyOneLandCoveyance) || 0,
        EstimatedAnnualCarring: Number(items?.estimatedAnnualCarring) || 0,
        TurnoverYears: Number(items?.turnoverYears) || 0,
        TurnoverYearsamount: Number(items?.turnoverYearsamount) || 0,
        TurnoverYearsValue: Number(items?.turnoverYearsValue) || 0,
        TurnoverYearsValueAmount: Number(items?.turnoverYearsValueAmount) || 0,
        TurnoverYearsTwo: Number(items?.turnoverYearsTwo) || 0,
        TurnoverYearsTwoAmount: Number(items?.turnoverYearsTwoAmount) || 0,
        BasicValueofEdition: items?.basicValueofEdition || '',
        Incoterms: items?.incoterms || 0,
        ConditionofCargo: items?.conditionofCargo || '',
        SurveyReportsFile: items?.SurveyReportsFile || '',
        InstituteCargoClauses: items?.instituteCargoClauses || '',
        WarAndStrikes: items?.WarAndStrikes || '',
        ExportImport: items?.ExportImport || '',
        InlandTransit: typeof items?.inlandTransit === 'object' ? '' : items?.inlandTransit || '',
        ProposalMarineModeOfConveyance: typeof items?.modeOfConveyance === 'object' ? '' : items?.modeOfConveyance || '',
        Description: items?.description || '',


        Contractor: items?.contractor || '',
        SubContrator: items?.subContrator || '',
        Trustees: items?.trustees || '',
        AgreedByInsurersPriorToAttachment: items?.agreedByInsurersPriorToAttachment || '',
        NatureDescriptionOfplantProject: items?.natureDescriptionOfplantProject || '',
        LocationOfProject: items?.locationOfProject || '',
        PeriodOfProject: items?.periodOfProject || '',
        NearestRailwayStation: items?.nearestRailwayStation || '',
        NearestOceanPort: items?.nearestOceanPort || '',
        TotalValueOfEquipmentToBeShipped: items?.totalValueOfEquipmentToBeShipped || 0,
        CountryOfOrginForAllEquiqment: items?.countryOfOrginForAllEquiqment || '',
        MethodOfTransportation: items?.methodOfTransportation || '',
        PackingSpecificationForAllCargoes: items?.packingSpecificationForAllCargoes || '',
        OnDeckStowageFile: items?.onDeckStowageFile || '',
        RiskAttachmentForTransitInsurnace: items?.riskAttachmentForTransitInsurnace || '',
        EndOfTransitInsurance: items?.endOfTransitInsurance || '',
        DateOfTestingScheduleToBegin: items?.dateOfTestingScheduleToBegin || '',
        TestPeriod: items?.testPeriod || '',
        ScheduleStartUpOrCommissioningDate: items?.scheduleStartUpOrCommissioningDate || '',
        DamageAndRepairsBeDoneLocally: items?.damageAndRepairsBeDoneLocally || '',
        ItemsSubjectToImportLicenseRestrictions: items?.itemsSubjectToImportLicenseRestrictions || '',
        ProvideFullDetails: items?.provideFullDetails || '',
        MachineryOrPlantUserEquipment: items?.machineryOrPlantUserEquipment || '',
        UploadTheFile: items?.uploadTheFile || '',
        SpecialExposureAndHazardAtProjectSite: items?.specialExposureAndHazardAtProjectSite || '',
        ClauseAgreementWithCAR: items?.clauseAgreementWithCAR || '',
        ConsequentialLossLimitRequest: items?.consequentialLossLimitRequest || '',
        IndemnityPeriodRequested: items?.indemnityPeriodRequested || '',
        TimeDeductibleRequested: items?.timeDeductibleRequested || '',
        DetailsOfProjectCargoAndHandlersFile: items?.detailsOfProjectCargoAndHandlersFile || '',
        BasicOfIndemnityProposed: items?.basicOfIndemnityProposed || '',
        EffectOfPlantOrProjectConstructionFile: items?.effectOfPlantOrProjectConstructionFile || '',
        TextFeildFileupload: items?.textFeildFileupload || '',
        DescriptionOfCargo: items?.descriptionOfCargo,
        WarAndSrcc: items?.warAndSrcc,
        DetailsOfVesselsAndConveyence: items?.detailsOfVesselsAndConveyence,
        ClaimsExperience: items?.claimsExperience,
        BillOfLadingAirwayBillParcelNo: items?.BillOfLadingAirwayBillParcelNo,
        ClaimExperienceFileUpload: items?.ClaimExperienceFileUpload,

        MarineCargoRequireOnDeckStowage: items?.MarineCargoRequireOnDeckStowage,
        ConveyanceForNonCriticalItemsPercentageTerms: items?.ConveyanceForNonCriticalItemsPercentageTerms,
        TransitInsuranceAttach: items?.TransitInsuranceAttach,
        PointToCease: items?.PointToCease,
        DateTestingScheduled: items?.DateTestingScheduled,
        LongWillTestingLast: items?.LongWillTestingLast,
        StartUpOrCommissioningDatePlantProject: items?.StartUpOrCommissioningDatePlantProject,
        RepairsBeDoneLocally: items?.RepairsBeDoneLocally,
        DoneByAssuredsPersonnel: items?.DoneByAssuredsPersonnel,
        DeferredUnpackingClauseBeRequired: items?.DeferredUnpackingClauseBeRequired,
        ProvideNamesOfExpeditersEmployed: items?.ProvideNamesOfExpeditersEmployed,
        ProvideNamesAndFullDetailsOfSurveyorsWishAppoint: items?.ProvideNamesAndFullDetailsOfSurveyorsWishAppoint,
        ProvideTheNamesSpecificClaimAdjusters: items?.ProvideTheNamesSpecificClaimAdjusters,
        AnyRouteSurveyAvailable: items?.AnyRouteSurveyAvailable,
        ProvideTheBasisOfIndemnityProposed: items?.ProvideTheBasisOfIndemnityProposed,
        OperationOfPlantOrProjectConstruction: items?.OperationOfPlantOrProjectConstruction,
        CommercialOperationsOrStartUpDateOfConstructionProject: items?.CommercialOperationsOrStartUpDateOfConstructionProject,
        AnyEquipmentnotrequiredProvideCargoInsurance: items?.AnyEquipmentnotrequiredProvideCargoInsurance,
        LimitRequiredVessel: items?.LimitRequiredVessel,
        LimitRequiredAir: items?.LimitRequiredAir,
        LimitRequiredTruck: items?.LimitRequiredTruck,
        AdditionalInformationPertainingCargoInsured: items?.AdditionalInformationPertainingCargoInsured,



        CargoFullShippingSchedule: items?.cargoFullShippingSchedule || [],
        TemporyStorgeOrDestination: items?.temporyStorgeOrDestination || [],
        ConsequetialLossWorkSheet: items?.consequetialLossWorkSheet || [],
        CommercialOperationOrStartUp: items?.commercialOperationOrStartUp || [],
        proposalAttachments: items?.proposalAttachments || [],
        DetailsOfCriticalItems: items?.detailsOfCriticalItems || [],


        OwnPower: items?.OwnPower,
        VoyageRiskOnly: items?.VoyageRiskOnly,
        Voyage: items?.voyage,
        PolicyTypeHull: items?.PolicyTypeHull,
        NameOfVesse: items?.NameOfVesse,
        FormerName: items?.FormerName,
        MasterName: items?.MasterName,
        Nationality: items?.Nationality,
        yearBuild: items?.yearBuild,
        PortOfRegistryAndRegisteredNo: items?.PortOfRegistryAndRegisteredNo,
        YearModified: items?.YearModified,
        Qualification: items?.Qualification,
        MaritimeExperience: items?.MaritimeExperience,
        CertificateOfCompetency: items?.CertificateOfCompetency,
        TypeOfTheVesse: items?.TypeOfTheVesse,
        ConstructionMaterial: items?.ConstructionMaterial,
        Flag: items?.Flag,
        NameOfTheClassificationSociety: items?.NameOfTheClassificationSociety,
        Tonnage: items?.Tonnage,
        GRTNRTDWT: items?.GRTNRTDWT,
        Loadline: items?.Loadline,
        Dimension: items?.Dimension,
        NationalityOfCrew: items?.NationalityOfCrew,
        NumbersOfCrew: items?.NumbersOfCrew,
        NumberOfCruewYears: items?.NumberOfCruewYears,
        TypeOfEngine: items?.TypeOfEngine,
        HorsePower: items?.HorsePower || 0,
        MaximumDesignedSpeedWithFullLoad: items?.MaximumDesignedSpeedWithFullLoad,
        NameOfTheBuilderYardExperience: items?.NameOfTheBuilderYardExperience,
        TypeOfTradeIsTheVesselorCraft: items?.TypeOfTradeIsTheVesselorCraft,
        StateTypeOfCargoConveyed: items?.StateTypeOfCargoConveyed,
        MaximumPermittedPassengerCapacity: items?.MaximumPermittedPassengerCapacity,
        OtherEquipmentsAndInstallation: items?.OtherEquipmentsAndInstallation,
        Others: items?.Others,
        ManufacturerAndDateOfMake: items?.ManufacturerAndDateOfMake,
        SerialAndModelNumber: items?.SerialAndModelNumber,
        BoilersManufacturerAndDateOfMake: items?.BoilersManufacturerAndDateOfMake,
        TypeOfFuelUsed: items?.TypeOfFuelUsed,
        StorageAndQuantityOfFuelCarried: items?.StorageAndQuantityOfFuelCarried,
        StateNumberOfOfficersAndCrews: items?.StateNumberOfOfficersAndCrews,
        NumberAndTypeOfFireExtinguisherPump: items?.NumberAndTypeOfFireExtinguisherPump,
        NumberAndTypeOfSafetyequipment: items?.NumberAndTypeOfSafetyequipment,
        OthersBePermittedToSailOrNavigateVessel: items?.OthersBePermittedToSailOrNavigateVessel,
        VesselNormallyMoored: items?.VesselNormallyMoored,
        StateCruisingTradingConfinesOrLimit: items?.StateCruisingTradingConfinesOrLimit,
        StateVoyageOrPeriodOfInsuranceCoverRequired: items?.StateVoyageOrPeriodOfInsuranceCoverRequired,
        StateTheAmountOfDeductible: items?.StateTheAmountOfDeductible,
        VesselPermittedFullDetails: items?.VesselPermittedFullDetails,
        LastTwelveMonthsOverhaulRepaired: items?.LastTwelveMonthsOverhaulRepaired,
        DetailsOfTugsNormallyUsed: items?.DetailsOfTugsNormallyUsed,
        ExpAndNatOfOwnerAndCrewOfTugs: items?.ExpAndNatOfOwnerAndCrewOfTugs,
        AnyContractOrAgreement: items?.AnyContractOrAgreement,
        MaximumNumberOfVessel: items?.MaximumNumberOfVessel,
        AnylocalOrGovernmentalAuthority: items?.AnylocalOrGovernmentalAuthority,
        CargoIsLoadedOnBoardTheTowedVesse: items?.CargoIsLoadedOnBoardTheTowedVesse,
        DeckhandsOnBoardTheTowedVessel: items?.DeckhandsOnBoardTheTowedVessel,
        AmountInsures: items?.AmountInsures,
        MarineHullClaimsExperience: items?.MarineHullClaimsExperience || [],
        MarineHullOperationsNavigationSurvey: items?.MarineHullOperationsNavigationSurvey || [],
        MarineHullDocumentEnclosed: items?.MarineHullDocumentEnclosed || [],
        MarineHullCoverageSpecification: items?.MarineHullCoverageSpecification || [],
        MarineHullPermisesInformation: items?.MarineHullPermisesInformation || [],
        MarineHullVesselDetails: items?.MarineHullVesselDetails || [],
        MarineHullVesselDimensions: items?.MarineHullVesselDimensions || [],
        MarineHullMachineryDetails: items?.MarineHullMachineryDetails || [],

        // Marine Liability
        lastFiveYearHasPort: items?.lastFiveYearHasPort || '',
        // lastFiveYearHasPortTrue: items?.lastFiveYearHasPortTrue || '',
        MarineHullVesselLiabilityInformation: items?.MarineHullVesselLiabilityInformation || [],
        MarineLiability: items?.MarineLiability,
        LegislationGovernsOperationPort: items?.LegislationGovernsOperationPort,
        ISPSCodeComplianceAtPort: items?.ISPSCodeComplianceAtPort,
        MarineLiabalityAdditionalInformation: items?.MarineLiabalityAdditionalInformation || [],
        MarineLiabilityPortThroughputRevenue: items?.MarineLiabilityPortThroughputRevenue || [],
        MarineLiabilityVariousServiceFacilitiesForms: items?.MarineLiabilityVariousServiceFacilitiesForms || [],
        MarineLiabilityInsurancePolicy: items?.MarineLiabilityInsurancePolicy || [],
        DescriptionMarineFacilities: items?.DescriptionMarineFacilities || [],
        DescriptionLandBasedFacilities: items?.DescriptionLandBasedFacilities || [],
        DescriptionOtherService: items?.DescriptionOtherService || [],
        MarineLiabilityContractorsEngagedByClient: items?.MarineLiabilityContractorsEngagedByClient || [],
        MarineLiabilityAgreedWithAnyContractors: items?.MarineLiabilityAgreedWithAnyContractors || [],
        MarineLiabilityActivities: items?.MarineLiabilityActivities || [],
        MarineLiabilityEmployeeInformation: items?.MarineLiabilityEmployeeInformation || [],
        MarineLiabilityCargoHandlingWorkers: items?.MarineLiabilityCargoHandlingWorkers || [],
        MarineLiabilityCustomerContactInformation: items?.MarineLiabilityCustomerContactInformation || [],
        MarineLiabilityStandardOfLibility: items?.MarineLiabilityStandardOfLibility || [],
        MarineLiabilityCustomerContactInformationContinued: items?.MarineLiabilityCustomerContactInformationContinued || [],
        MarineLiabilityClaims: items?.MarineLiabilityClaims || [],
        MarineLiabilityInsurenceClaimsHistory: items?.MarineLiabilityInsurenceClaimsHistory || [],
        MarineLiabilityOptionalExtensions: items?.MarineLiabilityOptionalExtensions || [],
        voyage: items?.voyage,


        Prodectionindemnity: items?.Prodectionindemnity || "",
        YearsOfExperience: items?.YearsOfExperience || '',
        NumbersOfCruewYears: items?.NumbersOfCruewYears || '',
        MarineHullLossHistory: items?.MarineHullLossHistory || [],
        MarineHullGeneralInformation: items?.MarineHullGeneralInformation || [],
        MarineHullVolumesAndParticularsOfTrade: items?.MarineHullVolumesAndParticularsOfTrade || [],
        MarineHullPercentagesOfParticularsOfTrade: items?.MarineHullPercentagesOfParticularsOfTrade || [],
        MarineHullTradingArea: items?.MarineHullTradingArea || [],
        MarineHullPercentageOfTradeInTradeRespectOfCargo: items?.MarineHullPercentageOfTradeInTradeRespectOfCargo || [],
        MarineHullNumberOfConsignmentOfCargoPerAnnum: items?.MarineHullNumberOfConsignmentOfCargoPerAnnum || [],
        MarineHullClaimsHistory: items?.MarineHullClaimsHistory || [],
        MarineHullContracts: items?.MarineHullContracts || [],
        MarineHullWareHousing: items?.MarineHullWareHousing || [],
        MarineHullWareHousingMaxValueCargoStored: items?.MarineHullWareHousingMaxValueCargoStored || [],
        MarineHullWareHousingPurpose: items?.MarineHullWareHousingPurpose || [],
        MarineHullWareHousingSecurityMeasure: items?.MarineHullWareHousingSecurityMeasure || [],
        MarineHullWareHousingAntiFireMeasures: items?.MarineHullWareHousingAntiFireMeasures || [],
        MarineHullOtherInformation: items?.MarineHullOtherInformation || [],

        MarineHullCargoHandled: items?.MarineHullCargoHandled || [],
        MarineHullConditionType: items?.MarineHullConditionType || '',
        MarineHullConditionValue: items?.MarineHullConditionValue || '',
        MarineHullClaimsExperienceFileUpload: items?.MarineHullClaimsExperienceFileUpload || '',
        MarineHullAmountInsured: items?.MarineHullAmountInsured || [],

        //Marine-DelayinStartup

        MarineDelayLocationOfProject: items?.MarineDelayLocationOfProject,
        InstallationProgrammewithAnticipated: items?.InstallationProgrammewithAnticipated,
        InstallationTime: items?.InstallationTime,
        ProjectFinanced: items?.ProjectFinanced,
        SpecialNature: items?.SpecialNature,
        ProductionCommenced: items?.ProductionCommenced,
        RepairsGuarantee: items?.RepairsGuarantee,
        ContigencyPlans: items?.ContigencyPlans,
        IndemnityPeriodDelayinStartUp: items?.IndemnityPeriodDelayinStartUp,
        DelayinStartUp: items?.DelayinStartUp,
        TimeExcessOpted: items?.TimeExcessOpted,

        MarineDelayPartyDeatailsUpload: items?.MarineDelayPartyDeatailsUpload || '',
        MarineDelayMajorComponentsUpload: items?.MarineDelayMajorComponentsUpload || '',
        MarineDelayManufacturerDetailsUpload: items?.MarineDelayManufacturerDetailsUpload || '',
        InstallationProgrammewithAnticipatedUpload: items?.InstallationProgrammewithAnticipatedUpload || '',
        SpecialNatureUpload: items?.SpecialNatureUpload || '',
        MarineDelayRepairUpload: items?.MarineDelayRepairUpload || '',
        ProductionCommencedUpload: items?.ProductionCommencedUpload || '',
        RepairsGuaranteeUpload: items?.RepairsGuaranteeUpload || '',
        ContigencyPlansUpload: items?.ContigencyPlansUpload || '',
        MarineDelayDetailsOfLossUpload: items?.MarineDelayDetailsOfLossUpload || '',

        MarineDelayPartyDeatails: items?.MarineDelayPartyDeatails || [],
        MarineDelayMajorComponents: items?.MarineDelayMajorComponents || [],
        MarineDelayManufacturerDetails: items?.MarineDelayManufacturerDetails || [],
        MarineDelayRepair: items?.MarineDelayRepair || [],
        MarineDelaySumInsuredAndClaimDetails: items?.MarineDelaySumInsuredAndClaimDetails || [],
        MarineDelayInStartUpDSU: items?.MarineDelayInStartUpDSU || [],
        MarineDelayDetailsOfLoss: items?.MarineDelayDetailsOfLoss || []

    }
}