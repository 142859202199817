import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { paths } from '../configs/constants';
import AuthLayout from '../components/layouts/authLayout';
import AppLayout from '../components/layouts/AppLayout';
import AdminNewLayout from '../components/layouts/AdminNewLayout';
import CedentNewLayout from '../components/layouts/CedentNewLayout';
import ExecutiveNewLayout from '../components/layouts/ExecutiveNewLayout';
import ReinsuranceNewLayout from '../components/layouts/ReinsuranceNewLayout';
import { AxiosProvider } from '../providers/AxiosProvider';
const CommonLogin = lazy(() => import('../pages/auth/commonLogin'));

const ExecutiveResponse2 = lazy(() => import('../pages/Executive/Reinsurance/Response2'));
const ExecutiveResponse1 = lazy(() => import('../pages/Executive/Reinsurance/Response1'));
const ExecutiveHistory = lazy(() => import('../pages/Executive/History'));
const ExecutiveSubmission = lazy(() => import('../pages/Executive/Reinsurance/Submission'));
const ExecutiveResponse = lazy(() => import('../pages/Executive/Reinsurance/Response'));
const ExecutiveProposalListing = lazy(() => import('../pages/Executive/Cedent/ProposalListing'));
const ExecutiveDashboard = lazy(() => import('../pages/Executive/Dashboard'));

const AdminAuditLog = lazy(() => import('../pages/Admin/Audit Log'));
const AdminPropopsalDetails = lazy(() => import('../pages/Admin/proposalDetails/index'));
const AdminViewPorposal1 = lazy(() => import('../pages/Admin/proposalDetails/ViewProposal1'));
const AdminViewPorposal2 = lazy(() => import('../pages/Admin/proposalDetails/ViewProposal2'));
const AdminViewPorposal3 = lazy(() => import('../pages/Admin/proposalDetails/ViewProposal3'));
const AdminViewPorposal4 = lazy(() => import('../pages/Admin/proposalDetails/ViewProposal4'));
const CedentMaintainence = lazy(() => import('../pages/Admin/Config/cedentMaintainence'));
const ReinsuranceMaintainence = lazy(() => import('../pages/Admin/Config/reinsuranceMaintainence'));
const CountryMaintainence = lazy(() => import('../pages/Admin/Config/countryMaintainence'));
const OperatingIndustries = lazy(() => import('../pages/Admin/Config/operatingIndustries'));
const RejectReasons = lazy(() => import('../pages/Admin/Config/rejectReasons'));
const OccupancyMaster = lazy(() => import('../pages/Admin/Config/occupanctMaster'));
const AccessMenu = lazy(() => import('../pages/Admin/Config/accessMenu'));
const FinancialYear = lazy(() => import('../pages/Admin/Config/financialyear'));
const BharatRebranches = lazy(() => import('../pages/Admin/Config/bharatRebranches'));
const RiskClassification = lazy(() => import('../pages/Admin/Config/riskClassification'));
const UserClassification = lazy(() => import('../pages/Admin/Config/userClassification'));
const AddonCoverage = lazy(() => import('../pages/Admin/Config/addOncoverage'));
const Underwritingclass = lazy(() => import('../pages/Admin/Config/underWritingclass'));
const SpecialClauses = lazy(() => import('../pages/Admin/Config/specialClauses'));
const UserDetails = lazy(() => import('../pages/Admin/Config/userDetails'));
const AdminDashboard = lazy(() => import('../pages/Admin/Dashboard'));
const ProposalDashboard = lazy(() => import('../pages/Admin/Dashboard/ProposalDashboard'));
const ProposalListing = lazy(() => import('../pages/Admin/Dashboard/ProposalListing'));
const ReinsuranceResponseReply = lazy(() => import('../pages/Reinsurence/Response/ResponseReply'));
const ReinsuranceViewResponse = lazy(() => import('../pages/Reinsurence/ViewResponse'));
const ReinsuranceResponse = lazy(() => import('../pages/Reinsurence/Response'));
const ReinsuranceEditProfile = lazy(() => import('../pages/Reinsurence/EditProfile'));
const ReinsuranceSettings = lazy(() => import('../pages/Reinsurence/Settings'));
const ReinsuranceMyApplication = lazy(() => import('../pages/Reinsurence/MyApplication'));
const ReinsuranceProposalListing = lazy(() => import('../pages/Reinsurence/ProposalListing'));
const ReinsuranceDashboard = lazy(() => import('../pages/Reinsurence/Dashboard'));
const CdentCreateNewPost4_0 = lazy(() => import('../pages/Cedent/CreateNewPost4_0'));
const CdentCreateNewPost4 = lazy(() => import('../pages/Cedent/CreateNewPost4'));
const CdentCreateNewPost2_5 = lazy(() => import('../pages/Cedent/CreateNewPost2_5'));
const CdentCreateNewPost3_5 = lazy(() => import('../pages/Cedent/CreateNewPost3_5'));
const CdentCreateNewPost2 = lazy(() => import('../pages/Cedent/CreateNewPost2'));
const CdentCreateNewPost1 = lazy(() => import('../pages/Cedent/CreateNewPost1'));
const CdentCreateNewPost3_0 = lazy(() => import('../pages/Cedent/CreateNewPost3_0'));
const CdentCreateNewPost3 = lazy(() => import('../pages/Cedent/CreateNewPost3'));
const CdentEditProfile = lazy(() => import('./../pages/Cedent/EditProfile'));
const CdentSettings = lazy(() => import('../pages/Cedent/Settings'));
const MailPage = lazy(() => import('../pages/Cedent/MailPage'));
const CdentHistory = lazy(() => import('./../pages/Cedent/History'));
const CdentPolicyPost = lazy(() => import('../pages/Cedent/ProposalListings'));
const CdentDashboard = lazy(() => import('../pages/Cedent/Dashboard'));
const SentRequest = lazy(() => import('../pages/auth/sentRequest'));
const ForgotPassword = lazy(() => import('./../pages/auth/forgotPassword'));
const BacisInfo = lazy(() => import('../pages/basicInfo'));
const ReinsuranceHistory = lazy(() => import('../pages/Reinsurence/History'));
const CedentNotifications=lazy(()=> import ('../pages/Cedent/Notifications'));
const ProposalPending = lazy(() => import('../pages/Admin/Dashboard/PendingProposal'));
const CedentReport = lazy(()=> import('../pages/Admin/Dashboard/CedentReport')) ;
const ReInsurerReport = lazy(()=> import('../pages/Admin/Dashboard/ReInsurerReport')) ;
const PerformanceReport = lazy(() => import('../pages/Admin/Dashboard/index'));
const ExecutiveAssign = lazy(()=>import('../pages/Admin/Config/executiveAssign'));
const ReAssignExecutive = lazy(() => import('../pages/Admin/ReAssignExecutive'));

const ReinsureViewPost1 = lazy(() => import('../pages/Reinsurence/ProposalListing/viewPost1'));
const ReinsureViewPost2 = lazy(() => import('../pages/Reinsurence/ProposalListing/viewPost2'));
const ReinsureViewPost2_5 = lazy(() => import('../pages/Reinsurence/ProposalListing/viewPost2_5'));
const ReinsureViewPost3_0 = lazy(() => import('../pages/Reinsurence/ProposalListing/viewPost3_0'));
const ReinsureViewPost3 = lazy(() => import('../pages/Reinsurence/ProposalListing/viewPost3'));
const ReinsureViewPost3_5 = lazy(() => import('../pages/Reinsurence/ProposalListing/viewPost3_5'));
const ReinsureViewPost4 = lazy(() => import('../pages/Reinsurence/ProposalListing/viewPost4'));
const ReinsureViewPost4_0 = lazy(() => import('../pages/Reinsurence/ProposalListing/viewPost4_0'));

const ExecutiveViewPost1 = lazy(() => import('../pages/Executive/Cedent/viewPost1'));
const ExecutiveViewPost2 = lazy(() => import('../pages/Executive/Cedent/viewPost2'));
const ExecutiveViewPost2_5 = lazy(() => import('../pages/Executive/Cedent/viewPost2_5'));
const ExecutiveViewPost3_0 = lazy(() => import('../pages/Executive/Cedent/viewPost3_0'));
const ExecutiveViewPost3 = lazy(() => import('../pages/Executive/Cedent/viewPost3'));
const ExecutiveViewPost3_5 = lazy(() => import('../pages/Executive/Cedent/viewPost3_5'));
const ExecutiveViewPost4 = lazy(() => import('../pages/Executive/Cedent/viewPost4'));
const ExecutiveViewPost4_0 = lazy(() => import('../pages/Executive/Cedent/viewPost4_0'));

const AdminViewPost1 = lazy(() => import('../pages/Admin/viewProposalDetails/viewPost1'));
const AdminViewPost2 = lazy(() => import('../pages/Admin/viewProposalDetails/viewPost2'));
const AdminViewPost2_5 = lazy(() => import('../pages/Admin/viewProposalDetails/viewPost2_5'));
const AdminViewPost3_0 = lazy(() => import('../pages/Admin/viewProposalDetails/viewPost3_0'));
const AdminViewPost3 = lazy(() => import('../pages/Admin/viewProposalDetails/viewPost3'));
const AdminViewPost3_5 = lazy(() => import('../pages/Admin/viewProposalDetails/viewPost3_5'));
const AdminViewPost4 = lazy(() => import('../pages/Admin/viewProposalDetails/viewPost4'));
const AdminViewPost4_0 = lazy(() => import('../pages/Admin/viewProposalDetails/viewPost4_0'));


const CedentViewPost1 = lazy(() => import('../pages/Cedent/ProposalListings/Cedent/viewPost1'));
const CedentViewPost2 = lazy(() => import('../pages/Cedent/ProposalListings/Cedent/viewPost2'));
const CedentViewPost2_5 = lazy(() => import('../pages/Cedent/ProposalListings/Cedent/viewPost2_5'));
const CedentViewPost3_0 = lazy(() => import('../pages/Cedent/ProposalListings/Cedent/viewPost3_0'));
const CedentViewPost3 = lazy(() => import('../pages/Cedent/ProposalListings/Cedent/viewPost3'));
const CedentViewPost3_5 = lazy(() => import('../pages/Cedent/ProposalListings/Cedent/viewPost3_5'));
const CedentViewPost4 = lazy(() => import('../pages/Cedent/ProposalListings/Cedent/viewPost4'));
const CedentViewPost4_0 = lazy(() => import('../pages/Cedent/ProposalListings/Cedent/viewPost4_0'));

const EmailPage = lazy(() => import('../pages/Admin/Dashboard/EmailPage'));

function RouteApp() {
    return (<>
        <AxiosProvider />
        <Routes>
            <Route path={paths.home} element={<AuthLayout />}>
                <Route path={paths.accounts.sentRequest} element={<Suspense fallback={""}><SentRequest /></Suspense>} />
                <Route path={paths.accounts.forgotPassword} element={<Suspense fallback={""}><ForgotPassword /></Suspense>} />
                <Route path={paths.auth.login} element={<Suspense fallback={""}><CommonLogin /></Suspense>} />
            </Route>

            {/* Cedent pages */}

            <Route path={paths.home} element={<CedentNewLayout />}>
                <Route path={paths.cedent.Dashboard} element={<Suspense fallback={""}><CdentDashboard /></Suspense>} />
                <Route path={paths.cedent.ProposalListings} element={<Suspense fallback={""}><CdentPolicyPost /></Suspense>} />
                <Route path={paths.cedent.History} element={<Suspense fallback={""}><CdentHistory /></Suspense>} />
                <Route path={paths.cedent.Settings} element={<Suspense fallback={""}><CdentSettings /></Suspense>} />
                <Route path={paths.cedent.MailPage} element={<Suspense fallback={""}><MailPage /></Suspense>} />
                <Route path={paths.cedent.EditProfile} element={<Suspense fallback={""}><CdentEditProfile /></Suspense>} />
                <Route path={paths.cedent.CreateNewPost2} element={<Suspense fallback={""}><CdentCreateNewPost2 /></Suspense>} />
                <Route path={paths.cedent.CreateNewPost2_5} element={<Suspense fallback={""}><CdentCreateNewPost2_5 /></Suspense>} />
                <Route path={paths.cedent.CreateNewPost3_0} element={<Suspense fallback={""}><CdentCreateNewPost3_0 /></Suspense>} />
                <Route path={paths.cedent.CreateNewPost3} element={<Suspense fallback={""}><CdentCreateNewPost3 /></Suspense>} />
                <Route path={paths.cedent.CreateNewPost3_5} element={<Suspense fallback={""}><CdentCreateNewPost3_5 /></Suspense>} />
                <Route path={paths.cedent.CreateNewPost1} element={<Suspense fallback={""}><CdentCreateNewPost1 /></Suspense>} />
                <Route path={paths.cedent.CreateNewPost4} element={<Suspense fallback={""}><CdentCreateNewPost4 /></Suspense>} />
                <Route path={paths.cedent.CreateNewPost4_0} element={<Suspense fallback={""}><CdentCreateNewPost4_0 /></Suspense>} />
                <Route path={paths.cedent.Notification} element={<Suspense fallback={""}><CedentNotifications/></Suspense>}/>
                
                <Route path={paths.cedent.viewPost1} element={<Suspense fallback={""}><CedentViewPost1 /></Suspense>} />
                <Route path={paths.cedent.viewPost2} element={<Suspense fallback={""}><CedentViewPost2 /></Suspense>} />
                <Route path={paths.cedent.viewPost2_5} element={<Suspense fallback={""}><CedentViewPost2_5 /></Suspense>} />
                <Route path={paths.cedent.viewPost3_0} element={<Suspense fallback={""}><CedentViewPost3_0 /></Suspense>} />
                <Route path={paths.cedent.viewPost3} element={<Suspense fallback={""}><CedentViewPost3 /></Suspense>} />
                <Route path={paths.cedent.viewPost3_5} element={<Suspense fallback={""}><CedentViewPost3_5 /></Suspense>} />
                <Route path={paths.cedent.viewPost4} element={<Suspense fallback={""}><CedentViewPost4 /></Suspense>} />
                <Route path={paths.cedent.viewPost4_0} element={<Suspense fallback={""}><CedentViewPost4_0 /></Suspense>} />
            </Route>

            {/* //Reinsurance */}
            <Route path={paths.home} element={<ReinsuranceNewLayout />}>
                <Route path={paths.reinsurance.Dashboard} element={<Suspense fallback={""}><ReinsuranceDashboard /></Suspense>} />
                <Route path={paths.reinsurance.MyApplication} element={<Suspense fallback={""}><ReinsuranceMyApplication /></Suspense>} />
                <Route path={paths.reinsurance.history} element={<Suspense fallback={""}><ReinsuranceHistory /></Suspense>} />
                <Route path={paths.reinsurance.Settings} element={<Suspense fallback={""}><ReinsuranceSettings /></Suspense>} />
                <Route path={paths.reinsurance.EditProfile} element={<Suspense fallback={""}><ReinsuranceEditProfile /></Suspense>} />
                <Route path={paths.reinsurance.Response} element={<Suspense fallback={""}><ReinsuranceResponse /></Suspense>} />
                <Route path={paths.reinsurance.ViewResponse} element={<Suspense fallback={""}><ReinsuranceViewResponse /></Suspense>} />
                <Route path={paths.reinsurance.ResponseReply} element={<Suspense fallback={""}><ReinsuranceResponseReply /></Suspense>} />
                <Route path={paths.reinsurance.ReInsurenceHistory} element={<Suspense fallback={""}><ReinsuranceHistory /></Suspense>} />

                <Route path={paths.reinsurance.ProposalListing} element={<Suspense fallback={""}><ReinsuranceProposalListing /></Suspense>} />
                <Route path={paths.reinsurance.viewPost1} element={<Suspense fallback={""}><ReinsureViewPost1 /></Suspense>} />
                <Route path={paths.reinsurance.viewPost2} element={<Suspense fallback={""}><ReinsureViewPost2 /></Suspense>} />
                <Route path={paths.reinsurance.viewPost2_5} element={<Suspense fallback={""}><ReinsureViewPost2_5 /></Suspense>} />
                <Route path={paths.reinsurance.viewPost3_0} element={<Suspense fallback={""}><ReinsureViewPost3_0 /></Suspense>} />
                <Route path={paths.reinsurance.viewPost3} element={<Suspense fallback={""}><ReinsureViewPost3 /></Suspense>} />
                <Route path={paths.reinsurance.viewPost3_5} element={<Suspense fallback={""}><ReinsureViewPost3_5 /></Suspense>} />
                <Route path={paths.reinsurance.viewPost4} element={<Suspense fallback={""}><ReinsureViewPost4 /></Suspense>} />
                <Route path={paths.reinsurance.viewPost4_0} element={<Suspense fallback={""}><ReinsureViewPost4_0 /></Suspense>} />
                
            </Route>



            {/* //Admin */}
            <Route path={paths.home} element={<AdminNewLayout />}>
                <Route path={paths.admin.Dashboard} element={<Suspense fallback={""} ><AdminDashboard /></Suspense>} />
                <Route path={paths.admin.proposalDashboard} element={<Suspense fallback={""} ><ProposalDashboard /></Suspense>} />
                <Route path={paths.admin.proposalListing} element={<Suspense fallback={""} ><ProposalListing /></Suspense>} />
                <Route path={paths.admin.proposalDetails} element={<Suspense fallback={""}><AdminPropopsalDetails /></Suspense>} />
                <Route path={paths.admin.viewProposal1} element={<Suspense fallback={""}><AdminViewPorposal1 /></Suspense>} />
                <Route path={paths.admin.viewProposal2} element={<Suspense fallback={""}><AdminViewPorposal2 /></Suspense>} />
                <Route path={paths.admin.viewProposal3} element={<Suspense fallback={""}><AdminViewPorposal3 /></Suspense>} />
                <Route path={paths.admin.viewProposal4} element={<Suspense fallback={""}><AdminViewPorposal4 /></Suspense>} />
                
                <Route path={paths.admin.cendetMaintainence} element={<Suspense fallback={""}><CedentMaintainence /></Suspense>} />
                <Route path={paths.admin.reinsuranceMaintainence} element={<Suspense fallback={""}><ReinsuranceMaintainence /></Suspense>} />
                <Route path={paths.admin.countryMaintainence} element={<Suspense fallback={""}><CountryMaintainence /></Suspense>} />
                <Route path={paths.admin.operatingIndustries} element={<Suspense fallback={""}><OperatingIndustries /></Suspense>} />
                <Route path={paths.admin.rejectReasons} element={<Suspense fallback={""}><RejectReasons /></Suspense>} />
                <Route path={paths.admin.occupancy} element={<Suspense fallback={""}><OccupancyMaster /></Suspense>} />
                <Route path={paths.admin.accessMenu} element={<Suspense fallback={""}><AccessMenu /></Suspense>} />
                <Route path={paths.admin.financialyear} element={<Suspense fallback={""}><FinancialYear /></Suspense>} />
                <Route path={paths.admin.bharatRebranches} element={<Suspense fallback={""}><BharatRebranches /></Suspense>} />
                <Route path={paths.admin.riskClassification} element={<Suspense fallback={""}><RiskClassification /></Suspense>} />
                <Route path={paths.admin.userClassification} element={<Suspense fallback={""}><UserClassification /></Suspense>} />
                <Route path={paths.admin.addOncoverage} element={<Suspense fallback={""}><AddonCoverage/></Suspense>} />
                <Route path={paths.admin.underWritingclass} element={<Suspense fallback={""}><Underwritingclass/></Suspense>} />
                <Route path={paths.admin.specialClauses} element={<Suspense fallback={""}><SpecialClauses/></Suspense>} />
                <Route path={paths.admin.userDetails} element={<Suspense fallback={""}><UserDetails /></Suspense>} />
                <Route path={paths.admin.AuditLog} element={<Suspense fallback={""}><AdminAuditLog /></Suspense>} />
                <Route path={paths.admin.PendingProposal} element={<Suspense fallback={""}><ProposalPending /></Suspense>} />
                <Route path={paths.admin.CedentReport} element={<Suspense fallback={""}><CedentReport /></Suspense>} />
                <Route path={paths.admin.ReInsurerReport} element={<Suspense fallback={""}><ReInsurerReport /></Suspense>} />
                <Route path={paths.admin.PerformanceReport} element={<Suspense fallback={""}><PerformanceReport /></Suspense>} />
                <Route path={paths.admin.executiveAssign} element={<Suspense fallback={""}><ExecutiveAssign /></Suspense>} />
                <Route path={paths.admin.reAssignExecutive} element={<Suspense fallback={""}><ReAssignExecutive /></Suspense>} />
                <Route path={paths.admin.adminMails} element={<Suspense fallback={""}><EmailPage /></Suspense>} />
                {/* <Route path={paths.admin.proposalUpload} element={<Suspense fallback={""}><ProposalUpload /></Suspense>} /> */}
            
                <Route path={paths.admin.viewPost1} element={<Suspense fallback={""}><AdminViewPost1 /></Suspense>} />
                <Route path={paths.admin.viewPost2} element={<Suspense fallback={""}><AdminViewPost2 /></Suspense>} />
                <Route path={paths.admin.viewPost2_5} element={<Suspense fallback={""}><AdminViewPost2_5 /></Suspense>} />
                <Route path={paths.admin.viewPost3_0} element={<Suspense fallback={""}><AdminViewPost3_0 /></Suspense>} />
                <Route path={paths.admin.viewPost3} element={<Suspense fallback={""}><AdminViewPost3 /></Suspense>} />
                <Route path={paths.admin.viewPost3_5} element={<Suspense fallback={""}><AdminViewPost3_5 /></Suspense>} />
                <Route path={paths.admin.viewPost4} element={<Suspense fallback={""}><AdminViewPost4 /></Suspense>} />
                <Route path={paths.admin.viewPost4_0} element={<Suspense fallback={""}><AdminViewPost4_0 /></Suspense>} />
            
            </Route>


            {/* //Executive */}

            <Route path={paths.home} element={<ExecutiveNewLayout />}>
                <Route path={paths.executive.Dashboard} element={<Suspense fallback={""}><ExecutiveDashboard /></Suspense>} />
                <Route path={paths.executive.Response} element={<Suspense fallback={""}><ExecutiveResponse /></Suspense>} />
                <Route path={paths.executive.Response1} element={<Suspense fallback={""}><ExecutiveResponse1 /></Suspense>} />
                <Route path={paths.executive.Response2} element={<Suspense fallback={""}><ExecutiveResponse2 /></Suspense>} />
                <Route path={paths.executive.Submission} element={<Suspense fallback={""}><ExecutiveSubmission /></Suspense>} />
                <Route path={paths.executive.History} element={<Suspense fallback={""}><ExecutiveHistory /></Suspense>} />
                <Route path={paths.executive.ProposalListing} element={<Suspense fallback={""}><ExecutiveProposalListing /></Suspense>} />
                {/* <Route path={paths.executive.proposalListing1} element={<Suspense fallback={""}><ExecutiveProposalListing1 /></Suspense>} />
                <Route path={paths.executive.proposalListing2} element={<Suspense fallback={""}><ExecutiveProposalListing2 /></Suspense>} />
                <Route path={paths.executive.proposalListing3} element={<Suspense fallback={""}><ExecutiveProposalListing3 /></Suspense>} />
                <Route path={paths.executive.proposalListing4} element={<Suspense fallback={""}><ExecutiveProposalListing4 /></Suspense>} /> */}

                <Route path={paths.executive.viewPost1} element={<Suspense fallback={""}><ExecutiveViewPost1 /></Suspense>} />
                <Route path={paths.executive.viewPost2} element={<Suspense fallback={""}><ExecutiveViewPost2 /></Suspense>} />
                <Route path={paths.executive.viewPost2_5} element={<Suspense fallback={""}><ExecutiveViewPost2_5 /></Suspense>} />
                <Route path={paths.executive.viewPost3_0} element={<Suspense fallback={""}><ExecutiveViewPost3_0 /></Suspense>} />
                <Route path={paths.executive.viewPost3} element={<Suspense fallback={""}><ExecutiveViewPost3 /></Suspense>} />
                <Route path={paths.executive.viewPost3_5} element={<Suspense fallback={""}><ExecutiveViewPost3_5 /></Suspense>} />
                <Route path={paths.executive.viewPost4} element={<Suspense fallback={""}><ExecutiveViewPost4 /></Suspense>} />
                <Route path={paths.executive.viewPost4_0} element={<Suspense fallback={""}><ExecutiveViewPost4_0 /></Suspense>} />

            </Route>

            <Route path={paths.home} element={<AppLayout />}>
                <Route path={paths.basicInfo} element={<Suspense fallback={""}><BacisInfo /></Suspense>} />
            </Route>
            <Route element={<AuthLayout />}>
                <Route index element={<Suspense fallback={""}><CommonLogin /></Suspense>} />
            </Route>
        </Routes>
    </>);
}

export default RouteApp;